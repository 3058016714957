<template>
  <div class="m1">
    <div class="m1-h">
      <h2>设置</h2>
    </div>
    <el-form ref="form" :model="form" label-width="200px">
      <el-form-item label="招聘信息显示天数">
        <el-select v-model="form.show_day" placeholder="请选择天数">
          <el-option label="30天" value="30"></el-option>
          <el-option label="15天" value="15"></el-option>
          <el-option label="7天" value="7"></el-option>
        </el-select>
        <el-button type="primary" @click="showDialog = true" style="margin-left:50px">info conllect</el-button>
        <el-button type="primary" @click="$router.push({path: 'setting/keyword'})" style="margin-left:50px">Key word</el-button>
      </el-form-item>
      <el-form-item label="新用户赠送积分">
        <el-input v-model="form.new_user_point" style="width: 169px"></el-input>
      </el-form-item>
      <el-form-item label="充值数额设置">
        <ul class="m1-ul">
          <li>
            <span>第一栏</span>
            <span>充值积分<el-input v-model="form.recharge[0].point" style="width: 106px"></el-input></span>
            <span>赠送积分<el-input v-model="form.recharge[0].give" style="width: 106px"></el-input></span>
            <span>原始价格<el-input v-model="form.recharge[0].original_price" style="width: 106px"></el-input></span>
            <span>折后价格<el-input v-model="form.recharge[0].price" style="width: 106px"></el-input></span>
          </li>
          <li>
            <span>第二栏</span>
            <span>充值积分<el-input v-model="form.recharge[1].point" style="width: 106px"></el-input></span>
            <span>赠送积分<el-input v-model="form.recharge[1].give" style="width: 106px"></el-input></span>
            <span>原始价格<el-input v-model="form.recharge[1].original_price" style="width: 106px"></el-input></span>
            <span>折后价格<el-input v-model="form.recharge[1].price" style="width: 106px"></el-input></span>
          </li>
          <li>
            <span>第三栏</span>
            <span>充值积分<el-input v-model="form.recharge[2].point" style="width: 106px"></el-input></span>
            <span>赠送积分<el-input v-model="form.recharge[2].give" style="width: 106px"></el-input></span>
            <span>原始价格<el-input v-model="form.recharge[2].original_price" style="width: 106px"></el-input></span>
            <span>折后价格<el-input v-model="form.recharge[2].price" style="width: 106px"></el-input></span>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="异常判定规则设置">
        <ul class="m1-ul">
          <li>
            <span>1s内超过条数<el-input v-model="form.abnormal[0].counts" style="width: 106px"></el-input></span>
            <span>5min内超过条数<el-input v-model="form.abnormal[1].counts" style="width: 106px"></el-input></span>
            <span>1h内超过条数<el-input v-model="form.abnormal[2].counts" style="width: 106px"></el-input></span>
            <span>24h内超过条数<el-input v-model="form.abnormal[3].counts" style="width: 106px"></el-input></span>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="提醒规则设置">
        <ul class="m1-ul">
          <li>
            <span>推送时间段
              <el-time-select
                style="width: 120px"
                v-model="form.push_begin_time"
                :picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '24:00',
                }"
                placeholder="选择时间">
              </el-time-select>
              -
              <el-time-select
                style="width: 120px"
                v-model="form.push_end_time"
                :picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30',
                }"
                placeholder="选择时间">
              </el-time-select>
            </span>
            <span>用户推送冷却时间<el-input v-model="form.push_cool_time" style="width: 106px"></el-input>分钟</span>
            <span>可推送信息时效<el-input v-model="form.push_period_time" style="width: 106px"></el-input>分钟内</span>
          </li>
        </ul>
      </el-form-item>
    </el-form>
    <div class="m1-btn">
      <el-button type="primary" @click="toSave()">保存</el-button>
    </div>
    <el-dialog
        :visible.sync="showDialog"
        width="80%">
        <el-tabs v-model="tabValue" @tab-click="getList">
          <el-tab-pane :label="item.name" :name="item.value" v-for="(item, index) in tabs">
            <ul class="dialog-ul" v-for="(item2, index2) in lists" v-if="item2.status != 2">
              <li>
                <label>ID <el-input v-model="item2.name" style="width: 200px;"></el-input></label>
                <div v-if="item2.is_expire == 1" style="color:red">账号过期</div>
                <div v-if="item2.is_out == 1" style="color:red">积分不足</div>
              </li>
              <li><label>TK <el-input type="textarea" :rows="2" v-model="item2.token" style="width: 550px; vertical-align:middle"></el-input></label></li>
              <li>
                <label>Province 
                  <el-select v-model="item2.province_ids" style="width:169px; margin-right:20px" placeholder="请选择省份" multiple>
                    <el-option v-for="item in areaLists" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </label>
              </li>
              <li>
                <label>
                  Amount
                  <el-input v-model="item2.min_count" style="width: 100px"></el-input>
                  -
                  <el-input v-model="item2.max_count" style="width: 100px"></el-input>
                </label>
                <a @click="delect(item2)" href="javascript:;">删除</a>
              </li>
            </ul>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="getList()"
              :current-page.sync="page"
              :total="total">
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="add()">新 增</el-button>
          <el-button type="primary" @click="toUserSave()">保 存</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  save,detail, list, areaLists, userSave, userDelete
} from '@/api/setting';
export default {
  name: 'index',
  data() {
    return {
      form: {
        show_day: '',
        new_user_point: '',
        abnormal: [
          {
            time: 1,
            counts: ''
          },
          {
            time: 300,
            counts: ''
          },
          {
            time: 3600,
            counts: ''
          },
          {
            time: 3600 * 24,
            counts: ''
          }
        ],
        recharge: [
          {
            point: '',
            give: '',
            original_price: '',
            price: ''
          },{
            point: '',
            give: '',
            original_price: '',
            price: ''
          },{
            point: '',
            give: '',
            original_price: '',
            price: ''
          }
        ],
        push_begin_time: '',
        push_end_time: '',
        push_cool_time: '',
        push_period_time: ''
      },
      showDialog: false,
      tabs: [
        {
          name: 'YP',
          value: '5'
        }, {
          name: 'DX',
          value: '4'
        }, {
          name: 'GD',
          value: '1'
        }, {
          name: 'ZF',
          value: '2'
        }, {
          name: 'MT',
          value: '3'
        }
      ],
      tabValue: '5',
      lists: [],
      areaLists: [],
      page: 1,
      total: 0
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.getDetail()
    this.getList()
    this.getAreaLists()
  },
  methods: {
    getDetail () {
      detail().then(res =>{
        this.form = res.data.data;
      })
    },
    toSave () {
      save(this.form).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
        
      })
    },
    toUserSave () {
      let lists = JSON.parse(JSON.stringify(this.lists))
      lists.forEach((item) => {
        item.province_ids = item.province_ids.join(',')
      })
      console.log(lists)
      userSave(lists).then(res =>{
        if (res.data.msg == 'ok') {
          this.getList()
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
        
      })
    },
    getList () {
      list({
        type: this.tabValue,
        page: this.page,
        page_size: 10

      }).then(res =>{
        this.lists = res.data.data.data;
        this.total = res.data.data.total;
        this.lists.forEach((item) => {
          item.province_ids = item.province_ids.split(',')
          console.log(item.province_ids)
        })
      })
    },
    getAreaLists () {
      areaLists().then(res =>{
        this.areaLists = res.data.data
        this.areaLists.forEach((item) => {
          item.value = item.value.toString()
        })
      })
    },
    add () {
      this.lists.push({
        name: '',
        type: this.tabValue,
        token: '',
        province_ids: [],
        min_count: '',
        max_count: '',
        status: 0
      })
    },
    delect(item) {
      item.status = 2
    }
  },
}
</script>
<style scoped>
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-ul li{
  margin-bottom: 47px;
  list-style: none;
}
.m1-ul span{
  margin-right: 52px;
  display: inline-block;
}
.m1-ul .el-input{
  margin: 0 10px;
}
.m1 >>> .el-form-item__label{
  text-align: left;
  padding-left: 45px;
}
.m1-btn{
  text-align: center;
}
.dialog-ul{
  display: flex;
}
.dialog-ul li{
  list-style: none;
  margin-right: 20px;
}
.dialog-ul .el-input{
  margin:10px;
}
.dialog-footer{
  text-align: center;
}
.dialog-footer button{
  margin: 0 40px;
}
</style>
